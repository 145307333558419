<!-- 大致粗略的简历信息 -->
<template>
  <div class="wrap" v-loading="loading">
    <el-button class="btn tianjia" @click="addClick">
      <span>添加员工</span>
    </el-button>
    <el-table :data="lists" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="身份证号">
              <span
                >：{{
                  props.row.id_number ? props.row.id_number : "未填写"
                }}</span
              >
            </el-form-item>
            <el-form-item label="入职时间">
              <span
                >：{{
                  props.row.date_joined ? props.row.date_joined : "未填写"
                }}</span
              >
            </el-form-item>
            <el-form-item label="婚否">
              <span v-if="props.row.marriage === 0">：未婚</span>
              <span v-else-if="props.row.marriage === 1">：已婚未育</span>
              <span v-else>：已婚已育</span>
            </el-form-item>
            <el-form-item label="毕业院校">
              <span
                >：{{
                  props.row.graduation ? props.row.graduation : "未填写"
                }}</span
              >
            </el-form-item>

            <el-form-item label="籍贯">
              <span
                >：{{
                  props.row.native_place ? props.row.native_place : "未填写"
                }}</span
              >
            </el-form-item>
            <el-form-item label="邮箱">
              <span>：{{ props.row.email ? props.row.email : "未填写" }}</span>
            </el-form-item>
            <el-form-item label="民族">
              <span
                >：{{ props.row.nation ? props.row.nation : "未填写" }}</span
              >
            </el-form-item>
            <el-form-item label="现住址">
              <span>：{{ props.row.place ? props.row.place : "未填写" }}</span>
            </el-form-item>
            <el-form-item label="猎之家账号">
              <span>：{{ props.row.username }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="头像">
        <template slot-scope="props">
          <img
            :src="
              props.row.photo
                ? props.row.photo
                : 'https://oss.hr24.com.cn/public/d682a3d591764450aed05a31e5a19741.png?BUCKET=png-bucket'
            "
            alt
            style="border: 1px #ccc solid"
          />
        </template>
        -->
      </el-table-column>
      <el-table-column label="姓名" prop="name"> </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="props">
          <div>
            {{ props.row.gender === 0 ? "男" : "女" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="年龄">
        <template slot-scope="props">
          <div>{{ props.row.years_old ? props.row.years_old : "未填写" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" prop="mobile"> </el-table-column>
      <el-table-column label="学历" prop="education"> </el-table-column>
      <el-table-column label="职位" prop="position"> </el-table-column>
      <el-table-column label="操作" min-width="90px">
        <template slot-scope="scope">
          <el-button size="mini" @click="bienjiClick(scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="sanchuClick(scope.row)"
            >离职</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      @close="closeClick"
    >
      <el-form
        :model="addForm"
        ref="ruleFormAdd"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rulesAdd"
        v-if="title === '添加员工'"
      >
        <!-- 添加的列表 -->
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :headers="{ token }"
            name="image"
            action="/api/liezhijia/v1/create_url/"
            :data="{ lie_token }"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-error="handleAvatarError"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="猎之家账号" prop="username">
          <el-input
            v-model="addForm.username"
            style="width: 300px"
            placeholder="请输入微信绑定授权的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="addForm.mobile"
            style="width: 300px"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input
            v-model="addForm.name"
            style="width: 300px"
            placeholder="请输入员工姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="入职时间" prop="date_joined">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="addForm.date_joined"
            style="width: 300px"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input
            v-model="addForm.position"
            style="width: 300px"
            placeholder="请输入职位"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select v-model="addForm.gender" placeholder="请选择性别">
            <el-option
              v-for="(item, index) in gender"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_number">
          <el-input
            v-model="addForm.id_number"
            style="width: 300px"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="年龄" prop="years_old">
          <el-input
            v-model="addForm.years_old"
            style="width: 300px"
            placeholder="请输入年龄"
          ></el-input>
        </el-form-item>
        <el-form-item label="婚否" prop="marriage">
          <el-select v-model="addForm.marriage" placeholder="请选择婚姻状况">
            <el-option
              v-for="(item, index) in marriage"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业院校" prop="graduation">
          <el-input
            v-model="addForm.graduation"
            style="width: 300px"
            placeholder="请输入毕业院校"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-select v-model="addForm.education" placeholder="请选择学历">
            <el-option
              v-for="item in xueli"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="addForm.email"
            style="width: 300px"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="民族" prop="nation">
          <el-input
            v-model="addForm.nation"
            style="width: 300px"
            placeholder="请输入民族"
          ></el-input>
        </el-form-item>
        <el-form-item label="籍贯" prop="native_place">
          <el-input
            v-model="addForm.native_place"
            style="width: 300px"
            placeholder="请输入籍贯"
          ></el-input>
        </el-form-item>
        <el-form-item label="现住址" prop="place">
          <el-input
            v-model="addForm.place"
            style="width: 300px"
            placeholder="请输入现住址"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        :model="updataFrom"
        ref="ruleFormUpdata"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rulesUpdata"
        v-else
      >
        <!-- 编辑用户 -->
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :headers="{ token }"
            name="image"
            action="/api/liezhijia/v1/create_url/"
            :data="{ lie_token }"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-error="handleAvatarError"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="姓名" prop="new_name">
          <el-input
            v-model="updataFrom.new_name"
            style="width: 300px"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="new_gender">
          <el-select v-model="updataFrom.new_gender" placeholder="请选择性别">
            <el-option
              v-for="(item, index) in gender"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="new_years_old">
          <el-input
            v-model="updataFrom.new_years_old"
            style="width: 300px"
            placeholder="请输入年龄"
          ></el-input>
        </el-form-item>

        <el-form-item label="身份证号" prop="new_id_number">
          <el-input
            v-model="updataFrom.new_id_number"
            style="width: 300px"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="婚否" prop="new_marriage">
          <el-select v-model="updataFrom.new_marriage" placeholder="请选择性别">
            <el-option
              v-for="(item, index) in marriage"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业院校" prop="new_graduation">
          <el-input
            v-model="updataFrom.new_graduation"
            style="width: 300px"
            placeholder="请输入毕业院校"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="new_education">
          <el-select
            v-model="updataFrom.new_education"
            placeholder="请选择学历"
          >
            <el-option
              v-for="item in xueli"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="手机号码" prop="new_mobile">
          <el-input
            v-model="updataFrom.new_mobile"
            style="width: 300px"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="new_position">
          <el-input
            v-model="updataFrom.new_position"
            style="width: 300px"
            placeholder="请输入职位"
          ></el-input>
        </el-form-item>
        <el-form-item label="入职时间" prop="new_date_joined">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="updataFrom.new_date_joined"
            style="width: 300px"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="猎之家账号" prop="new_username">
          <el-input
            v-model="updataFrom.new_username"
            style="width: 300px"
            placeholder="请输入微信绑定授权的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="new_email">
          <el-input
            v-model="updataFrom.new_email"
            style="width: 300px"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="民族" prop="new_nation">
          <el-input
            v-model="updataFrom.new_nation"
            style="width: 300px"
            placeholder="请输入民族"
          ></el-input>
        </el-form-item>
        <el-form-item label="籍贯 " prop="new_native_place">
          <el-input
            v-model="updataFrom.new_native_place"
            style="width: 300px"
            placeholder="请输入籍贯 "
          ></el-input>
        </el-form-item>
        <el-form-item label="现住址" prop="new_place">
          <el-input
            v-model="updataFrom.new_place"
            style="width: 300px"
            placeholder="请输入现住址"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="okClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  staff_list,
  staff_shan,
  create_user,
  staff_detail,
  staff_Put,
} from "@/api/me/index";
export default {
  //import引入的组件需要注入到对象中才能使用
  props: {},
  data() {
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    var validatorShenfen = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("身份证不能为空"));
      } else if (
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/.test(
          value
        )
      ) {
        callback(new Error("身份证格式错误"));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
      imageUrl: "",
      add: false,
      loading: true,
      token: sessionStorage.getItem("token"),
      lie_token: sessionStorage.getItem("lie_token"),
      gender: [
        {
          label: "男",
          value: 0,
        },
        {
          label: "女",
          value: 1,
        },
      ],
      marriage: [
        {
          label: "未婚",
          value: 0,
        },
        {
          label: "已婚未育",
          value: 1,
        },
        {
          label: "已婚已育",
          value: 2,
        },
      ],
      xueli: [
        {
          value: "初中及以下",
          label: "初中及以下",
        },
        {
          value: "中专",
          label: "中专",
        },
        {
          value: "高中",
          label: "高中",
        },
        {
          value: "大专",
          label: "大专",
        },
        {
          value: "本科",
          label: "本科",
        },
        {
          value: "硕士",
          label: "硕士",
        },
        {
          value: "博士",
          label: "博士",
        },
        {
          value: "MBA",
          label: "MBA",
        },
      ],
      dialogVisible: false,
      addForm: {},
      updataFrom: {},
      title: "",
      lists: [],
      oldUpdataFrom: {},
      Putobj: {
        lie_token: sessionStorage.getItem("lie_token"),
      },
      rulesAdd: {
        username: [
          { required: true, message: "请输入登陆手机号", trigger: "blur" },
          { validator: validatorPhone, trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validatorPhone, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        date_joined: [
          { required: true, message: "请选择入职时间", trigger: "change" },
        ],
        position: [{ required: true, message: "请输入职位", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        id_number: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          { validator: validatorShenfen, trigger: "blur" },
        ],
        years_old: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        marriage: [
          { required: true, message: "请选择婚姻状况", trigger: "change" },
        ],
        graduation: [
          { required: true, message: "请输入毕业院校", trigger: "blur" },
        ],
        education: [{ required: true, message: "请选择学历", trigger: "change" }],
      },
      rulesUpdata: {
        new_date_joined: [
          { required: true, message: "请选择入职时间", trigger: "change" },
        ],
        new_position: [
          { required: true, message: "请输入岗位", trigger: "blur" },
        ],
        new_username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        new_mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        new_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        new_gender: [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        new_id_number: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          { validator: validatorShenfen, trigger: "blur" },
        ],
        new_years_old: [
          { required: true, message: "请输入年龄", trigger: "blur" },
        ],
        new_marriage: [
          { required: true, message: "请选择婚姻状况", trigger: "change" },
        ],
        new_graduation: [
          { required: true, message: "请填写毕业院校", trigger: "blur" },
        ],
        new_education: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      if (response.code != 0) {
        this.$message.error(response.msg);
        return;
      }
      if (this.title === "添加员工") {
        this.addForm.photo = response.context.image;
      } else {
        this.updataFrom.new_photo = response.context.image;
      }
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handleAvatarError(err) {
      this.$message.error(err.msg);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    okClick() {
      if (this.title === "添加员工") {
        var obj = this.addForm;
        obj.lie_token = sessionStorage.getItem("lie_token");
        this.$refs.ruleFormAdd.validate((valid) => {
          if (!valid) {
            return;
          }
          create_user(obj).then((res) => {
            if (res.code === "0") {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.list();
              this.dialogVisible = false;
            } else {
              this.$message(res.msg);
            }
          });
        });
      } else {
        // 编辑是改哪个传那个，这样简单但是耗费性能
        for (const key in this.updataFrom) {
          if (this.oldUpdataFrom[key] !== this.updataFrom[key]) {
            this.Putobj[key] = this.updataFrom[key];
          }
        }
        console.log(this.Putobj);
        staff_Put(JSON.stringify(this.Putobj)).then((res) => {
          if (res.code === "0") {
            this.$message({
              message: "编辑成功",
              type: "success",
            });
            this.list();
            this.dialogVisible = false;
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
    closeClick() {
      this.addForm = {};
      this.updataFrom = {};
      this.oldUpdataFrom = {};
      this.Putobj = {
        lie_token: sessionStorage.getItem("lie_token"),
      };
      this.imageUrl = "";
      this.$refs.ruleFormAdd.resetFields();
      this.$refs.ruleFormUpdata.resetFields();
    },
    addClick() {
      this.title = "添加员工";
      this.dialogVisible = true;
    },
    bienjiClick(item) {
      var obj = {
        user_id: item.user_id,
        lie_token: sessionStorage.getItem("lie_token"),
      };
      this.title = "编辑员工";
      // 获取详细信息
      staff_detail(obj).then((res) => {
        if (res.code === "0") {
          var newTime = res.context.date_joined.split("T");
          console.log(this.updataFrom, newTime);
          this.imageUrl = res.context.photo;
          // 点击编辑回显数据
          this.updataFrom = {
            new_date_joined: newTime[0],
            new_position: res.context.positon,
            new_username: res.context.username,
            new_mobile: res.context.mobile,
            new_name: res.context.name,
            new_gender: res.context.gender,
            new_id_number: res.context.id_number,
            new_years_old: res.context.years_old,
            new_marriage: res.context.marriage,
            new_graduation: res.context.graduation,
            new_education: res.context.education,
            new_photo: res.context.photo,
            new_email: res.context.email,
            new_nation: res.context.nation,
            new_native_place: res.context.native_place,
            new_place: res.context.place,
          };
          // 与回显编辑做比较的参数
          this.oldUpdataFrom = {
            new_date_joined: newTime[0],
            new_position: res.context.positon,
            new_username: res.context.username,
            new_mobile: res.context.mobile,
            new_name: res.context.name,
            new_gender: res.context.gender,
            new_id_number: res.context.id_number,
            new_years_old: res.context.years_old,
            new_marriage: res.context.marriage,
            new_graduation: res.context.graduation,
            new_education: res.context.education,
            new_photo: res.context.photo,
            new_email: res.context.email,
            new_nation: res.context.nation,
            new_native_place: res.context.native_place,
            new_place: res.context.place,
          };
          // 给Putobj添加参数id值
          this.Putobj.user_id = item.user_id;
        }
      });
      this.dialogVisible = true;
    },
    sanchuClick(item) {
      console.log(item);
      var obj = {
        lie_token: sessionStorage.getItem("lie_token"),
        user_id: item.user_id,
      };
      this.$confirm("确定该员工已离职", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          staff_shan(JSON.stringify(obj)).then((res) => {
            if (res.code === "0") {
              this.$message({
                message: "离职成功",
                type: "success",
              });
              this.list();
            } else {
              this.$message(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    xiangqingCLick(item) {
      this.$router.push({
        path: "/personnel_xiangqing",
        query: {
          item: item,
        },
      });
    },
    list() {
      // 获取列表数据
      this.loading = true;
      var obj = {
        grade: "",
        lie_token: sessionStorage.getItem("lie_token"),
        is_leave:1
      };
      console.log(obj);
      staff_list(obj).then((res) => {
        if (res.code === "0") {
          var arr = res.sub_data;
          arr.forEach((res) => {
            res.date_joined = res.date_joined.split("T")[0];
          });
          console.log(arr);
          this.lists = arr;
          this.loading = false;
          console.log(this.lists);
        } else {
          this.$message(res.msg);
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.list();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
.el-form-item__label {
  width: 93px;
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #ccc;
}
.avatar-uploader {
  margin-left: 10px;
  margin-top: -40px;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.weizhi {
  border: 1px #ccc solid;
}
.tianjia {
  margin-left: 1000px;
}

.wrap {
  @include padding;
  // padding: 0;
  // padding-top: 0;
  position: relative;
  min-height: 800px;
  // box-sizing: border-box;
}
/* //@import url(); 引入公共css类 */
/* 自我评价 */
.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}
i {
  padding-left: 7px;
  margin-left: 7px;

  border-left: 1px solid #e4e7ed;
}
img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.name {
  overflow: hidden;
}
.name > p {
  float: left;
  margin-top: 27px;
  margin-left: 95px;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 310px;
  height: 100%;

  /* background-color: red; */
}
// .selfAppraisal {
//   /* 多行超出隐藏 */
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   overflow: hidden;
//   height: 57px;
//   height: 48px\0;
// }
/* 期望 */
.expect {
  float: left;

  width: 450px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 查看 */
// .look {
//   // float: left;

//   position: absolute;
//   top: -20px;
//   right: 15px;
//   display: none;
//   z-index: 50;
// }
// .look /deep/ .el-button {
//   background-color: #ff8f00;
//   color: #fff;
//   font-size: 16px;
//   border: none;
// }
.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}
.functionKey {
  width: 180px;
  height: 80px;
  border-radius: 10px;
  text-align: center;
  margin-left: 20px;
}
.functionKey > p {
  // :#ccc ;
  float: left;
  margin: 0 14px;
  line-height: 80px;
  position: relative;
}
.functionKey > p > span {
  font-size: 30px;
  color: #020101;
  cursor: pointer;
}

.disableds {
  pointer-events: none;
  color: grey;
}
</style>
